import React from 'react';
import { graphql } from 'gatsby';
import IndexPage from 'components/pages/IndexPage';

const seo = {
  title: 'Q°emotion – Analyse sémantique et émotionnelle d’avis clients',
  keywords: [
    'Analyse émotions',
    'IA',
    'Solution SaaS',
    'Q emotion',
    'Avis client',
    'Retours clients',
    'Feedbacks',
    'Verbatim',
  ],
  description:
    'Q°emotion est une solution d’analyse sémantique et émotionnelle qui traite automatiquement vos verbatim, messages et avis clients. Repérez et diminuez les irritants dans votre parcours, réduisez votre taux d’attrition ou augmentez votre fidélité client grâce à notre plateforme SaaS. Testez le produit gratuitement dès maintenant !',
};

export default props => <IndexPage {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockIntro: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "introBlock" }
        lang: { eq: "fr" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        title
      }
    }
    dataBlockSubintro: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "subintroBlock" }
        lang: { eq: "fr" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        subTitle
      }
    }
    imageSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockImage" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataImageMinLeftSubintro: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "subintroBlock" }
          component: { eq: "imageMinLeft" }
        }
      }
      sort: { fields: [frontmatter___relatedImage] }
    ) {
      nodes {
        frontmatter {
          relatedImage
        }
      }
    }
    dataImageMinRightSubintro: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "subintroBlock" }
          component: { eq: "imageMinRight" }
        }
      }
      sort: { fields: [frontmatter___relatedImage] }
    ) {
      nodes {
        frontmatter {
          relatedImage
        }
      }
    }
    imageMinSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockImage/minImage" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    imageClientSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockClients" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockActionBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "actionBlock" }
          lang: { eq: "fr" }
          component: { eq: "block" }
        }
      }
      sort: { fields: [frontmatter___numBlockAction] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numBlockAction
          component
        }
      }
    }
    dataBlockActionTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "actionBlock" }
        lang: { eq: "fr" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    imageActionBlock: file(
      relativeDirectory: { eq: "indexPage/actionBlock/fr" }
    ) {
      childImageSharp {
        fluid(maxWidth: 350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dataBlockOperationTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "operationBlock" }
        lang: { eq: "fr" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockOperationBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "operationBlock" }
          lang: { eq: "fr" }
          component: { eq: "subTitle" }
        }
      }
      sort: { fields: [frontmatter___numBlockOperation] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numBlockOperation
          relatedImage
        }
      }
    }
    imageOperationBlock: allFile(
      filter: { relativeDirectory: { eq: "indexPage/operationBlock/fr" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataTestimonialBlock: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionMarketing" }
          blockType: { eq: "testimonialBlock" }
          lang: { eq: "fr" }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          author
          role
          image
          youtubeID
          youtubeTitle
        }
      }
    }
    imageTestimonialBlock: allFile(
      filter: { relativeDirectory: { eq: "bubbleImages" }, base: { in: [] } }
      sort: { fields: [id], order: [ASC] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imagesQuotesTestimonialBlock: allFile(
      filter: {
        relativeDirectory: {
          eq: "solutionPage/analyseVerbatim/testimonialBlock"
        }
      }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "fr" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
    dataBlockVideoTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "videoBlock" }
        lang: { eq: "fr" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockVideoBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "videoBlock" }
          lang: { eq: "fr" }
          component: { eq: "slide" }
        }
      }
      sort: { fields: [frontmatter___numSlide] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numSlide
          relatedImage
          link
        }
      }
    }
    imageVideoBlock: allFile(
      filter: { relativeDirectory: { eq: "indexPage/videoBlock/slides" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`;
